import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Edit, Save, Cancel } from '@mui/icons-material';
import './App.css';

function App() {
  const [tech1, setTech1] = useState('');
  const [cust1textcompletion, setCust1TextCompletion] = useState('');
  const [notes1, setNotes1] = useState('I was just at DEF Industries for 6 hours, replacing a faulty component in their machinery.');
  const [cust1, setCust1] = useState('');
  const [hours1, setHours1] = useState('');
  const [work1, setWork1] = useState('');
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [csvMessage, setCsvMessage] = useState('');
  const missingFieldMessage = (!tech1 || !cust1 || !cust1textcompletion || !hours1 || !work1) ? 'Please fill out all required fields' : null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch('http://zanoni.myddns.me:8443', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notes1 }),
      });
      const data = await response.json();
      setCust1(data.cust1Text);
      if (data.cust1TextCompletionText && data.hours1Text) {
        setCust1TextCompletion(data.cust1TextCompletionText.trim().replace(/[^0-9]/g, ''));
        setHours1(data.hours1Text.trim().replace(/[^0-9]/g, ''));
      }
      setWork1(data.work1Text);
      setLoading(false);
      console.log('cust1:', data.cust1Text);
      console.log('cust1TextCompletion:', data.cust1TextCompletionText.trim().replace(/[^0-9]/g, ''));
      console.log('hours1:', data.hours1Text.trim().replace(/[^0-9]/g, ''));
      console.log('work1:', data.work1Text);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSaveToCSV = async () => {
    const data = { tech1, cust1, cust1textcompletion, hours1, work1 };
    try {
      const response = await fetch('http://zanoni.myddns.me:3007', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error('Error saving to CSV');
      }
  
      try {
        const result = await response.json();
        console.log('Data saved to CSV:', result);
  
        // Show the message and hide it after 3 seconds
        setCsvMessage('Data saved to CSV successfully!');
        setTimeout(() => {
          setCsvMessage('');
        }, 3000);
      } catch (jsonError) {
        throw new Error('Unexpected server response');
      }
    } catch (error) {
      console.log(error);
      setCsvMessage('Error saving data to CSV');
      setTimeout(() => {
        setCsvMessage('');
      }, 3000);
    }
  };
  

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    // Reset the textareas to their original values
    setCust1('');
    setHours1('');
    setWork1('');
  };

  const handleFocus = (event) => {
    // select the text inside the textarea
    event.target.select();
  };

  return (
    <Box sx={{ maxWidth: '75%', margin: '0 auto' }}>
      <Box mb={3}>
        <Typography variant="h5" fontWeight="bold" textTransform="uppercase">
          Tech Name:
        </Typography>
        <TextField
          fullWidth
          value={tech1}
          onChange={(e) => setTech1(e.target.value)}
          required
        />
      </Box>
      <Box mb={3}>
        <form onSubmit={handleSubmit}>
          <Typography variant="h5" fontWeight="bold" textTransform="uppercase">
            Raw job info/notes
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={10}
            value={notes1}
            onChange={(e) => setNotes1(e.target.value)}
            onFocus={handleFocus}
          />
          <Box mt={2}>
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Box>
        </form>
        {loading && (
  <Box mt={2} display="flex" justifyContent="center">
    <CircularProgress />
  </Box>
)}
</Box>

<Box mb={3}>
  <Typography variant="h4" fontWeight="bold" textTransform="uppercase">
    Customer:
  </Typography>
  {isEditing ? (
    <TextField fullWidth value={cust1} onChange={(e) => setCust1(e.target.value)} />
  ) : (
    <TextField fullWidth value={cust1} InputProps={{ readOnly: true }} />
  )}
  <TextField fullWidth value={cust1textcompletion} InputProps={{ readOnly: true }} />
</Box>

<Box mb={3}>
  <Typography variant="h4" fontWeight="bold" textTransform="uppercase">
    Hours:
  </Typography>
  {isEditing ? (
    <TextField fullWidth value={hours1} onChange={(e) => setHours1(e.target.value)} />
  ) : (
    <TextField fullWidth value={hours1} InputProps={{ readOnly: true }} />
  )}
</Box>

<Box mb={3}>
  <Typography variant="h4" fontWeight="bold" textTransform="uppercase">
    Work Description
  </Typography>
  {isEditing ? (
    <TextField
      fullWidth
      multiline
      rows={5}
      value={work1}
      onChange={(e) => setWork1(e.target.value)}
    />
  ) : (
    <TextField fullWidth multiline rows={5} value={work1} InputProps={{ readOnly: true }} />
  )}
</Box>

<Box mb={3}>
  {isEditing ? (
    <>
      <Button onClick={handleSave} startIcon={<Save />}>
        Save
      </Button>
      <Button onClick={handleCancel} startIcon={<Cancel />}>
        Cancel
      </Button>
    </>
  ) : (
    <Button onClick={handleEdit} startIcon={<Edit />}>
      Edit
    </Button>
  )}
</Box>

<Box mb={3}>
  <Button onClick={handleSaveToCSV} variant="contained">
    Save to CSV
  </Button>
  {missingFieldMessage && (
    <Typography color="error" fontWeight="bold">
      {missingFieldMessage}
    </Typography>
  )}
  {csvMessage && <Typography>{csvMessage}</Typography>}
</Box>
</Box>
)
  }
export default App;
